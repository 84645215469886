const BlueBox = ({ text, value, percentage }) => {
  var percentageValue = `${percentage}`;
  var remainingPercentage = `${95 - percentage}`;

  return (
    <div className="blue-box">
      <h4 className="text-center t-white">{text}</h4>
      <h3 className="text-center t-white">{value}</h3>
      <div
        className="d-flex justify-content-center"
        style={{ width: "90%", paddingTop: "5px", margin: "0 auto" }}
      >
        <div
          style={{
            height: "4px",
            width: percentageValue + "%",
            backgroundColor: "#CFDE41",
            marginTop: "3px",
          }}
        ></div>
        <div
          style={{
            height: "10px",
            width: "5%",
            backgroundColor: "#000000",
          }}
        ></div>
        <div
          style={{
            height: "4px",
            width: remainingPercentage + "%",
            backgroundColor: "#00ADBD",
            marginTop: "3px",
          }}
        ></div>
      </div>
    </div>
  );
};

export default BlueBox;

import WavyBgTop from "../images/wavy-bg-top.png";

const WavyTop = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${WavyBgTop})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "130px",
      }}
    >
      &nbsp;
    </div>
  );
};

export default WavyTop;

import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ChipLogo from "../images/chip.png";
import CrumbLogo from "../images/crumb.png";
import { useState, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

const About = () => {

  useEffect(() => {
    AOS.init({ duration: 1000 })
  }, []);

  const [paraOneHeading, setParaOneHeading] = useState("");
  const [paraOneText, setParaOneText] = useState("");
  const [paraTwoHeading, setParaTwoHeading] = useState("");
  const [paraTwoText, setParaTwoText] = useState("");
  const [paraThreeHeading, setParaThreeHeading] = useState("");
  const [paraThreeText, setParaThreeText] = useState("");

  const getAboutData = async () => {
    let response = await fetch(
      "https://binance.stitchedbackend.com/api/stitched/get-stitched-about",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      }
    );

    response = await response.json();
    if (response.error) {
      console.warn(response);
    } else {
      setParaOneHeading(response.aboutData.para_one_heading);
      setParaOneText(response.aboutData.para_one_text);
      setParaTwoHeading(response.aboutData.para_two_heading);
      setParaTwoText(response.aboutData.para_two_text);
      setParaThreeHeading(response.aboutData.para_three_heading);
      setParaThreeText(response.aboutData.para_three_text);
    }
  };

  useEffect(() => {
    getAboutData();
  }, []);

  return (
    <Container>
      <Row id="about">
        <Col lg={7}>
          <h2 className="t-white about-heading" data-aos="fade-up">{paraOneHeading}</h2>
          <p className="t-white pe-3 about-para" data-aos="fade-up">{paraOneText}</p>
        </Col>
        <Col lg={5}>
          <Container>
            <Row className="about-section-logos" data-aos="fade-up">
              <Col xs={6} sm={6} md={6} lg={12} className="text-center">
                <img src={ChipLogo} alt="Chip" width={170} />
              </Col>
              <Col xs={6} sm={6} md={6} lg={12} className="text-center mt-4">
                <img src={CrumbLogo} alt="Chip" width={170} />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row className="mt-5" id="problems">
        <Col md={12} lg={7}>
          <div className="blank-box" data-aos="fade-up">
            <h2 className="t-white">{paraTwoHeading}</h2>
            <p className="t-white extra-line-height">{paraTwoText}</p>
          </div>
        </Col>
        <Col md={12} lg={5}>
          <div className="blank-box" data-aos="fade-up">
            <h2 className="t-white">{paraThreeHeading}</h2>
            <p className="t-white">{paraThreeText}</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default About;

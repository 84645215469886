import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BlueBox from "./BlueBox";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";

const Utilization = ({ utilizationHeading }) => {

  useEffect(() => {
    AOS.init({ duration: 1000 })
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#040447",
        paddingTop: "20px",
        paddingBottom: "20px",
      }}
    >
      <Container>
        <h2 className="t-white headingText text-center mt-5 mb-3" data-aos="fade-up">
          {utilizationHeading}
        </h2>
        <Row className="justify-content-lg-center" data-aos="zoom-in">
          <Col xs={6} md={4} lg>
            <BlueBox text={"Reserved"} value={"5%"} percentage={5} />
          </Col>
          <Col xs={6} md={4} lg>
            <BlueBox text={"Team"} value={"10%"} percentage={10} />
          </Col>
          <Col xs={6} md={4} lg>
            <BlueBox text={"Development"} value={"15%"} percentage={15} />
          </Col>
          <Col xs={6} md={4} lg>
            <BlueBox text={"Public Sales"} value={"35%"} percentage={35} />
          </Col>
          <Col xs={6} md={4} lg>
            <BlueBox text={"Marketing"} value={"16.1%"} percentage={16} />
          </Col>
          <Col xs={6} md={4} lg>
            <BlueBox text={"Liquidity"} value={"12%"} percentage={12} />
          </Col>
          <Col xs={6} md={4} lg>
            <BlueBox text={"Private Seed"} value={"2.3%"} percentage={3} />
          </Col>
          <Col xs={6} md={4} lg>
            <BlueBox text={"Seed 1"} value={"2.3%"} percentage={3} />
          </Col>
          <Col xs={6} md={4} lg>
            <BlueBox text={"Seed 2"} value={"2.3%"} percentage={3} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Utilization;

import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ItemBox from "./ItemBox";
import Utilization from "./Utilization";
import WavyTop from "./WavyTop";
import WavyBottom from "./WavyBottom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";

const Tokenomics = ({ tokenomicsHeading, tokenomicsPara, utilizationHeading }) => {

  useEffect(() => {
    AOS.init({ duration: 1000 })
  }, []);

  return (
    <>
      <WavyTop />
      <div
        style={{
          backgroundColor: "#040447",
        }}
        className="tokenomics" id="tokenomics"
      >
        <Container>
          <h2 className="text-center t-white headingText" data-aos="fade-up">{tokenomicsHeading}</h2>
          <p className="t-white text-center" data-aos="fade-up">
            {tokenomicsPara}
          </p>

          <Row className="mt-4" data-aos="zoom-in">
            <Col xs={6} sm={6} md={4} lg={2}>
              <ItemBox value={"Chip"} text={"Token Name"} />
            </Col>
            <Col xs={6} sm={6} md={4} lg={2}>
              <ItemBox value={"CHIP"} text={"Token Ticker"} />
            </Col>
            <Col xs={6} sm={6} md={4} lg={2}>
              <ItemBox value={"0"} text={"Decimal"} />
            </Col>
            <Col xs={6} sm={6} md={4} lg={2}>
              <ItemBox value={"500,000,000"} text={"Total Supply"} />
            </Col>
            <Col xs={6} sm={6} md={4} lg={2}>
              <ItemBox value={"Cardano"} text={"Network"} />
            </Col>
            <Col xs={6} sm={6} md={4} lg={2}>
              <ItemBox value={"Utility Token"} text={"Token type"} />
            </Col>
          </Row>

          {/* <Row className="justify-content-lg-center" data-aos="zoom-in">
            <Col xs={6} sm={6} md={4} lg={2}>
              <ItemBox value={"Chip"} text={"Token Name"} />
            </Col>
            <Col xs={6} sm={6} md={4} lg={2}>
              <ItemBox value={"CHIP"} text={"Token Ticker"} />
            </Col>
            <Col xs={6} sm={6} md={4} lg={2}>
              <ItemBox value={"0"} text={"Decimal"} />
            </Col>
            <Col xs={6} sm={6} md={4} lg={2}>
              <ItemBox value={"500,000,000"} text={"Total Supply"} />
            </Col>
            <Col xs={6} sm={6} md={4} lg={2}>
              <ItemBox value={"Cardano"} text={"Network"} />
            </Col>
          </Row> */}

        </Container>
      </div>

      <Utilization utilizationHeading={utilizationHeading} />

      <WavyBottom />
    </>
  );
};

export default Tokenomics;

import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import WavyTop from "./WavyTop";
import chipsLogo from "../images/chips.png";
import { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Footer = ({ footerPara, stitchedEmail, copyrightText }) => {

  useEffect(() => {
    AOS.init({ duration: 1000 })
  }, []);

  return (
    <>
      <WavyTop />
      <div
        style={{
          backgroundColor: "#040447",
        }}
        className="footer t-white"
      >
        <Container>
          <Row>
            <Col md={4} data-aos="fade-up">
              <img
                className="footer-chip-logo"
                src={chipsLogo}
                alt="Chips"
                width={110}
              />
              <p className="footer-para">{footerPara}</p>
              <strong
                className="mail-link"
                onClick={() => (window.location = "mailto:kl@stitched.us")}
              >
                {stitchedEmail}
              </strong>
            </Col>
            <Col md={4} data-aos="fade-up">
              <div className="footer-block">
                <strong className="underLined">Quick Links</strong>
                <hr className="hr" />
                <ul className="footer-list">
                  <li>
                    <a href="#tokenomics">Tokenomics</a>
                  </li>
                  <li>
                    <a href="#features">Features</a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={4} data-aos="fade-up">
              <div className="footer-block">
                <strong>Useful Links</strong>
                <hr className="hr" />
                <ul className="footer-list">
                  <li>
                    <a href="/Chips-Privacy-Policy.pdf" target="_blank">
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a href="/Chips-Terms-and-Conditions.pdf" target="_blank">
                      Term and Condition
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="copyright-text">{copyrightText}</p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Footer;

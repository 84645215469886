import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import ChipTokenLogo from "../images/chip.png";
import CrumbTokenLogo from "../images/crumb.png";
import { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

const NavigationBar = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, []);

  return (
    <Navbar variant="dark" expand="lg" >
      <Container className="pt-2" data-aos="zoom-in">
        <img className="ms-auto d-none d-lg-block" src={ChipTokenLogo} width={67} alt={"Chip"} />

        <Navbar.Toggle className="ms-auto" aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <Nav.Link href="#tokenomics">Tokenomics</Nav.Link>
            <Nav.Link href="#about">About</Nav.Link>
            <Nav.Link href="#features">Features</Nav.Link>
            <Nav.Link href="#problems">Problems</Nav.Link>
            <Nav.Link href="#roadmap">Roadmap</Nav.Link>
            <Nav.Link href="/chips-whitepaper.pdf" target="_blank">Whitepaper</Nav.Link>
            <Nav.Link href="https://chips.stitched.us" target="_blank">Buy Chip</Nav.Link>
          </Nav>
        </Navbar.Collapse>

        <img className="me-auto  d-none d-lg-block" src={CrumbTokenLogo} width={80} alt={"Crumb"} />
      </Container>
    </Navbar>
  );
};

export default NavigationBar;

import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "./Navbar";
import ChipsLogo from "../images/chips.png";
import { useState, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import androidLogo from "../images/android.png";
import iosLogo from "../images/ios.png";

const Header = () => {

  useEffect(() => {
    AOS.init({ duration: 1000 })
  }, []);

  const [headerPara, setHeaderPara] = useState('');
  const [chipBtnTxt, setChipBtnTxt] = useState('');
  const [chipBtnLink, setChipBtnLink] = useState('');
  const [crumbBtnTxt, setCrumbBtnTxt] = useState('');
  const [crumbBtnLink, setCrumbBtnLink] = useState('');
  const [chipFingerPrint, setChipFingerPrint] = useState('');
  const [crumbFingerPrint, setCrumbFingerPrint] = useState('');
  const [androidAppLink, setAndroidAppLink] = useState('');
  const [iosAppLink, setIosAppLink] = useState('');

  const getHeaderData = async () => {

    let response = await fetch("https://binance.stitchedbackend.com/api/stitched/get-stitched-header", {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    });

    response = await response.json();
    console.log(response);
    if (response.error) {
      console.warn(response)
    }
    else {
      setHeaderPara(response.headerData.header_para);
      setChipBtnTxt(response.headerData.chip_button_text);
      setChipBtnLink(response.headerData.chip_button_link);
      setCrumbBtnTxt(response.headerData.crumb_button_text);
      setCrumbBtnLink(response.headerData.crumb_button_link);
      setChipFingerPrint(response.headerData.chip_fingerprint);
      setCrumbFingerPrint(response.headerData.crumb_fingerprint);
      setAndroidAppLink(response.headerData.android_app_link);
      setIosAppLink(response.headerData.ios_app_link);
    }

  }

  useEffect(() => {
    getHeaderData();
  }, [])


  return (
    <div className="siteHeader">
      <Navbar />
      <Container id="header">
        <Row data-aos="fade-up">
          <Col xs={12}>
            <img
              className="mx-auto d-block mt-lg-5"
              src={ChipsLogo}
              width={200}
              alt="Chips"
            />
          </Col>
          <Col xs={12}>
            <h1 className="headerText text-center my-4 mx-auto animate__zoomIn">{headerPara}</h1>
          </Col>
          <Col md={6}>
            <a href={chipBtnLink} target="_blank" rel="noreferrer">
              <button className="stitchedBtn headerBtn1">{chipBtnTxt}</button>
            </a>
          </Col>
          <Col md={6}>
            <a href={crumbBtnLink} target="_blank" rel="noreferrer">
              <button className="stitchedBtnTransparent headerBtn2">{crumbBtnTxt}</button>
            </a>
          </Col>

          <Col xs={12}>
            <div className="fingerprintWrapper">
              <span className="fingerprintHeading stitchedGradient">
                Chip Fingerprint:
              </span>

              <span className="fingerprintValue stitchedGradient">
                {chipFingerPrint}
              </span>
            </div>
          </Col>

          <Col xs={12}>
            <div className="fingerprintWrapper">
              <span className="fingerprintHeading stitchedGradient">
                Crumb Fingerprint:
              </span>
              <span className="fingerprintValue stitchedGradient">
                {crumbFingerPrint}
              </span>
            </div>
          </Col>

          <Col xs={12}>
            <div className="mt-4 mt-lg-5 appDownloadLinks">
              <a href={androidAppLink} rel="noreferrer" target="_blank">
                <img
                  src={androidLogo}
                  alt="Chips"
                  className="appDownloadImg"
                />                      
              </a>
              <a href={iosAppLink} rel="noreferrer" target="_blank">
                <img
                  src={iosLogo}
                  alt="Chips"
                  width={160}
                  className="appDownloadImg"
                />
              </a>
            </div>
          </Col>

        </Row>
      </Container>
    </div>
  );
};

export default Header;
